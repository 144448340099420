import { createSlice } from '@reduxjs/toolkit'

// Define the initial state using that type
const initialState = {
  query: '',
  ingredients: [],
  productFiltered: [],
}

export const productSlice = createSlice({
  name: 'ProductSearcher',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    initProductSearcher: (state, action) => {},
    setQuery: (state, {payload}) => {
      const { query} = payload;
      /* --------------------------- handle query string -------------------------- */
      state.query = query;
    },
    setIngredients: (state, {payload}) => {
      const {ingredients} = payload;
      let newDataIngredient = [...state.ingredients];
      const position = newDataIngredient.indexOf(ingredients[0]);
      if (position > -1) {
        newDataIngredient.splice(position, 1);
      } else {
        newDataIngredient = [...state.ingredients, ...ingredients]
      }
      state.ingredients = newDataIngredient;
    },
    performQuery: (state, action) => {},
    setProductFiltered: (state, {payload}) => {
      state.productFiltered = payload
    }
  },
})

export const {
  initProductSearcher,
  performQuery,
  setQuery,
  setIngredients,
  setProductFiltered,
} = productSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state) => state.counter.value

export default productSlice.reducer