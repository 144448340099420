'use client'
import { useRef } from 'react';
import { Provider } from 'react-redux';
import { makeStore, sagaMiddleware } from '@/redux/store';
import rootSaga from '@/sagas/index';
import { initializeSession } from '@/redux/slices/customer-session'
import { initializeCount } from '@/redux/slices/counterSlice';
// import { setCurrentLanguage } from '@/redux/slices/textsSlice'
// import { getCookie } from '@/utils/common/get-cookie';

export default function StoreProvider({ count, children }) {
  const storeRef = useRef(null);

  if (!storeRef.current) {
    const store = makeStore();
    store.sagaTask = sagaMiddleware.run(rootSaga);
    store.dispatch(initializeCount({ count }));
    store.dispatch(initializeSession());
    // const language = getCookie('language') || 'es';
    // store.dispatch(setCurrentLanguage({ language }))
    storeRef.current = store;
  }



  return <Provider store={storeRef.current}>{children}</Provider>;
}
