import { all, takeLatest } from "redux-saga/effects";
import { initAnchorModalListener, addModal } from "@/sagas/modals-saga";
import { getBusinessByCP, performQuery } from '@/sagas/restaurant-searcher'
import { initProductSearcher, performQuery as performProductQuery } from "@/sagas/product-searcher";

function* rootSaga() {
  yield all([
    takeLatest("RestaurantSearcher/performQuery", performQuery),
    takeLatest("RestaurantSearcher/getBussinessByCp", getBusinessByCP),
    takeLatest("initAnchorModalListener", initAnchorModalListener),
    takeLatest("ProductSearcher/initProductSearcher", initProductSearcher),
    takeLatest("ProductSearcher/performQuery", performProductQuery),
    takeLatest("addModal", addModal)
  ]);
}

export default rootSaga;
