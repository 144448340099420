import Cookies from 'universal-cookie';
import { NextApiRequest, NextApiResponse } from 'next';

// Function to set a cookie
export function setCookie(
  req: NextApiRequest | null,
  res: NextApiResponse | null,
  name: string,
  value: string,
  options: any = {}
) {
  options.path = options.path || '/';
  options.overwrite = true;
  const cookies = new Cookies(req ? req.headers.cookie : null);
  cookies.set(name, value, options);

  if (res) {
    res.setHeader('Set-Cookie', cookies.get(name, { ...options }));
  }
}

// Function to get a cookie
export function getCookie(
  req: NextApiRequest | null,
  res: NextApiResponse | null,
  name: string
) {
  const cookies = new Cookies(req ? req.headers.cookie : null);
  return cookies.get(name);
}
