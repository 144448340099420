import { createSlice } from '@reduxjs/toolkit'

// Define the initial state using that type
const initialState = {
  cpSelected: 0,
  allBusinessOfCp: null,
  businessFiltered: null,
}

export const counterSlice = createSlice({
  name: 'RestaurantSearcher',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    getBussinessByCp: (state, action) => { console.log(action) },
    performQuery: (state, action) => { console.log(action)},
    setBusinessByCP: (state, { payload }) => {
      const { cp, businessList, bussinesDict } = payload;
      if (!state.allBusinessOfCp) {
        state.allBusinessOfCp = {}
      }
      if (!state.allBusinessOfCp[cp]) {
        state.allBusinessOfCp[cp] = {}
      }
      state.allBusinessOfCp[cp].data = businessList;
      state.allBusinessOfCp[cp].dict = bussinesDict
    },
    setBusinessFiltered: (state, { payload }) => {
      state.businessFiltered = payload
    }
  },
})

export const { 
  getBussinessByCp,
  performQuery,
  setBusinessByCP,
  setBusinessFiltered,
} = counterSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state) => state.counter.value

export default counterSlice.reducer