import { createSlice } from '@reduxjs/toolkit'
import React from 'react';
import dynamic from "next/dynamic";
let Modal = null;

const initialState = {
  modalImport: null,
  modalHistory: [],
  Modal: null,
};

export const modalSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    initAnchorModalListener: (state, action) => {

    },
    addModal: (state, action) => {
      const modalImport = import("@/components/modal");
      Modal = dynamic(() => modalImport, {ssr: false});
      const modalInstance = <Modal />;
      state.modalImport = modalImport;
      state.Modal = modalInstance;
    },
    setModalHistory: (state, action) => {
      state.modalHistory =  action.payload.data
    }

  }
})

export const { actions } = modalSlice;


export default modalSlice.reducer;