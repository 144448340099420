import { createSlice } from '@reduxjs/toolkit'

// Define the initial state using that type
const initialState = {
  value: 0,
}

export const counterSlice = createSlice({
  name: 'counter',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    increment: (state) => { state.value += 1 },
    decrement: (state) => { state.value -= 1 },
    incrementByAmount: (state, action) => { state.value += action.payload },
    initializeCount: (state, action) => { state.value = action.payload.count;}
  },
})

export const { increment, decrement, incrementByAmount, initializeCount } = counterSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state) => state.counter.value

export default counterSlice.reducer