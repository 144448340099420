import createSagaMiddleware from 'redux-saga';
import { configureStore } from '@reduxjs/toolkit'
import CounterReducer from '@/redux/slices/counterSlice'
import ModalReducer from '@/redux/slices/modalSlice'
import customerSession from '@/redux/slices/customer-session'
import resturantSearcher from '@/redux/slices/restaurant-searcher'
import productSearcher from '@/redux/slices/product-searcher'

export const sagaMiddleware = createSagaMiddleware();

export const makeStore = () => {
  return configureStore({
    reducer: {
      resturantSearcher,
      productSearcher,
      counter: CounterReducer,
      modals: ModalReducer,
      customerSession,
    },
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ thunk: false }).concat(sagaMiddleware),
    // enhancers: (getDefaultEnhancers) => getDefaultEnhancers({ autoBatch: false })
  })
}

// sagaMiddleware.run(rootSaga);