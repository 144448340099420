import { call, all, select } from 'redux-saga/effects';

let initSearcher = null;
let performQueryMethod = null;

export function* initProductSearcher({payload}) {
  if(!initSearcher){
    ({ initSearcher } = yield call(() => import('./create-search-engine')))
  }
  yield call(initSearcher, { products: payload })
}


export function* performQuery() {
  const { query, ingredients } = yield select(state => state.productSearcher);
  if(!performQueryMethod){
    ({ performQuery: performQueryMethod } = yield call(() => import('./create-search-engine')))
  }
  yield call(performQueryMethod, {query, ingredients})
}